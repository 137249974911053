import { DatePipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, ParamMap, Router } from '@angular/router';
import { FormHelper } from '@helpers/forms/forms';
import { Helper } from '@helpers/helper/helper';
import { APIAppSettingI } from '@models/app-settings.model';
import { IncidentDetail } from '@models/incident.model';
import { OptionInteface, RadioButton } from '@models/option.model';
import { AppUser } from '@models/user.model';
import { ApiErrorHandlerService } from '@services/api/error/api-error-handler.service';
import { IncidentService } from '@services/api/incidents/incidents.service';
import { LoginService } from '@services/api/login/login.service';
import { ReportService } from '@services/api/report/report.service';
import { SeasonsService } from '@services/api/seasons/seasons.service';
import { Subscription } from 'rxjs';
import { INCIDENT_TYPE_MAP_KEY, INCIDENT_TYPES, MAX_ALLOWED_CAUSE_OPTIONS } from './new-report.conf';

@Component({
    selector: 'app-new-report',
    templateUrl: './new-report.component.html',
    styleUrls: ['./new-report.component.scss']
})
export class NewReportComponent implements OnInit, OnDestroy {
    public riskIncidentsOptions: OptionInteface[];
    public hipoRiskIncidentsOptions: OptionInteface[];
    public contractOptions: OptionInteface[];
    public activityListOptions: OptionInteface[];
    public workDayHourOptions: OptionInteface[];
    public rootCausesOptions: OptionInteface[];
    public inmediateCausesOptions: OptionInteface[];

    public saved: boolean = false;
    public sending: boolean = false;
    public reportFiles: File[] = [];
    public actionsFiles: File[] = [];
    public descriptionFiles: File[] = [];
    public existingReportFiles: any[] = [];
    public existingActionsFiles: any[] = [];
    public existingDescriptionFiles: any[] = [];

    public reportForm: FormGroup;
    public submitted: boolean = false;
    public user: AppUser;

    public today: Date = new Date();
    public maxDate: Date = new Date();
    public minDate: Date = new Date(this.maxDate.setDate(this.maxDate.getDate() - 30));

    public maxAllowedCauseOption: number = MAX_ALLOWED_CAUSE_OPTIONS;

    public myDateFilter = (d: Date | null): boolean => {
        return d?.getTime() > this.minDate.getTime() && d?.getTime() < this.today.getTime();
    };

    public subscription?: Subscription;
    public incident?: IncidentDetail;
    public loading: boolean = false;
    public isDraft: boolean = false;
    public seasonStartDate: Date | null = null;
    public seasonFinishDate: Date | null = null;

    public incidentTypes: RadioButton[] = INCIDENT_TYPES;
    public selectedTypeOption: RadioButton = this.incidentTypes[0];

    public incidentTypesKey = INCIDENT_TYPE_MAP_KEY;
    public canEdit: boolean;

    constructor(
        private formBuilder: FormBuilder,
        private _snackBar: MatSnackBar,
        private reportService: ReportService,
        private formHelper: FormHelper,
        private loginService: LoginService,
        private datepipe: DatePipe,
        private route: ActivatedRoute,
        private incidentService: IncidentService,
        private router: Router,
        private helper: Helper,
        private seasonService: SeasonsService,
        private errorService: ApiErrorHandlerService
    ) {}

    ngOnInit(): void {
        this.loading = true;
        this.user = this.loginService.currentAppUserValue;
        this.subscription = this.route.paramMap.subscribe((params: ParamMap) => {
            const incident = params.get('report_id') || null;
            if (incident) {
                this.getSeasonFinishDate(Number(incident));
            } else {
                this.getSeasonFinishDate();
            }
        });
    }

    ngOnDestroy(): void {
        this.subscription.unsubscribe();
    }

    buildForm(): void {
        this.reportForm = this.formBuilder.group({
            contract: [null, [Validators.required]],
            activity_incident: [null, [Validators.required]],
            risk_incident: [null, [Validators.required]],
            incident_date: [null, [Validators.required]],
            incident_hour: [null, [Validators.required]],
            work_day_hour: [null, [Validators.required]],
            location: [null, [Validators.required]],
            description: [null, [Validators.required]],
            investigation_files: [null, [Validators.required]],
            inmediate_causes: [null, [Validators.required]],
            root_causes: [null, [Validators.required]],
            preventive_action: [null, [Validators.required]],
            preventive_actions_files: [null, [Validators.required]],
            description_files: [null, [Validators.required]],
            incident_type: [null, [Validators.required]]
        });
        this.manageFormValidators();
        this.loading = false;
    }

    fillForm(): void {
        const inmediateCauses: string[] = this.incident.inmediate_causes.map(cause => cause.id);
        const rootCauses: string[] = this.incident.root_causes.map(cause => cause.id);
        let investigationFiles: '1' | null = null;
        if (this.incident.investigation_files.length) {
            investigationFiles = '1';
            this.existingReportFiles = this.incident.investigation_files.map(file => file.id);
        }
        let preventiveActions: '1' | null = null;
        if (this.incident.preventive_actions_files.length) {
            preventiveActions = '1';
            this.existingActionsFiles = this.incident.preventive_actions_files.map(file => file.id);
        }
        let descriptionFiles: '1' | null = null;
        if (this.incident.description_files.length) {
            descriptionFiles = '1';
            this.existingDescriptionFiles = this.incident.description_files.map(file => file.id);
        }
        let incidentHour = null;
        if (this.incident.incident_hour) {
            incidentHour = this.incident.incident_hour.substring(0, 5);
            this.incident.incident_hour = incidentHour;
        }
        const incidentId =
            this.incident.activity_incident && this.incident.activity_incident.id
                ? this.incident.activity_incident.id
                : null;
        const riskId =
            this.incident.risk_incident && this.incident.risk_incident.id ? this.incident.risk_incident.id : null;
        const incidentDate = this.incident.incident_date ? this.incident.incident_date : null;
        const workDayHour = this.incident.work_day_hour ? this.incident.work_day_hour : null;

        this.reportForm = this.formBuilder.group({
            contract: [this.incident.contract.id || null, [Validators.required]],
            activity_incident: [incidentId, [Validators.required]],
            risk_incident: [riskId, [Validators.required]],
            incident_date: [incidentDate, [Validators.required]],
            incident_hour: [incidentHour, [Validators.required]],
            work_day_hour: [workDayHour, [Validators.required]],
            location: [this.incident.location || null, [Validators.required]],
            description: [this.incident.description || null, [Validators.required]],
            investigation_files: [investigationFiles, [Validators.required]],
            inmediate_causes: [inmediateCauses || null, [Validators.required]],
            root_causes: [rootCauses || null, [Validators.required]],
            preventive_action: [this.incident.preventive_actions || null, [Validators.required]],
            preventive_actions_files: [preventiveActions, [Validators.required]],
            description_files: [descriptionFiles, [Validators.required]],
            incident_type: [this.incident.incident_type, [Validators.required]]
        });

        this.manageFormValidators();

        this.loading = false;
    }

    get f() {
        return this.reportForm.controls;
    }

    async getSeasonFinishDate(incidentId?: number): Promise<void> {
        const { finishDate, startDate } = await this.seasonService.getSeasonDates();
        this.seasonFinishDate = finishDate;
        this.seasonStartDate = startDate;
        this.checkSeasonDate(incidentId);
    }

    checkSeasonDate(incidentId?: number): void {
        if (this.seasonFinishDate && this.seasonStartDate) {
            const isValid = this.helper.isBetweenDates(this.seasonStartDate, this.seasonFinishDate);
            if (isValid) {
                if (incidentId) {
                    this.getIncidentData(incidentId);
                } else {
                    this.buildForm();
                    this.getSelectsData();
                    this.loadSettings();
                }
            } else {
                this.goBackToReport(incidentId);
            }
        } else {
            this.goBackToReport(incidentId);
        }
    }

    getIncidentData(incident: number): void {
        this.incidentService.getIncidentsList(null, incident).subscribe(
            (response: IncidentDetail) => {
                this.incident = response;
                // Check if the incident is still a draft to be edited and
                // if the user can edit this incident
                this.canEdit = this.helper.canModifyIncident(this.user, this.incident);
                if (this.canEdit) {
                    this.getSelectsData();
                    this.loadSettings();
                } else {
                    this.goBackToReport(incident);
                }
            },
            error => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );
    }

    onSubmit(isDraft: boolean): void {
        if (this.sending) return;
        this.submitted = true;
        this.sending = true;
        this.isDraft = isDraft;
        if (
            (!isDraft && this.reportForm.valid) ||
            (isDraft &&
                this.reportForm.controls.description?.valid &&
                this.reportForm.controls.incident_date?.valid &&
                this.reportForm.controls.contract?.valid)
        ) {
            let report = this.formHelper.retrieveFormData(this.reportForm, [
                'investigation_files',
                'preventive_actions_files',
                'description_files'
            ]);
            report['investigation_files'] = this.reportFiles;
            report['preventive_actions_files'] = this.actionsFiles;
            report['description_files'] = this.descriptionFiles;
            report['is_draft'] = isDraft;

            // report date parser
            report['incident_date'] = this.datepipe.transform(report['incident_date'], 'y-MM-dd');
            // report time parser
            report['incident_hour'] = report['incident_hour']
                ? report['incident_hour'] + ':' + new Date().getSeconds()
                : null;

            report['existing_investigation_files'] = this.existingReportFiles.map(file => file);
            report['existing_actions_files'] = this.existingActionsFiles.map(file => file);
            report['existing_description_files'] = this.existingDescriptionFiles.map(file => file);

            // If incident already exists we will always do a patch to backend
            // backend will save as draft or publish incident depending if all form fields
            // are complete, and looking at "is_draft" variable
            const type = this.incident ? 'update' : 'post';
            const id = this.incident ? this.incident.id : undefined;
            this.reportService.createIncident(report, type, id).subscribe(
                () => {
                    this.saved = true;
                    this.sending = false;
                },
                error => {
                    this.errorService.handleErrorFeedback(error?.error?.key);
                    this.sending = false;
                }
            );
        } else {
            let draftError = 'No puedes guardar como borrador un formulario completo';
            if (
                isDraft &&
                (this.reportForm.controls.description?.invalid ||
                    this.reportForm.controls.incident_date?.invalid ||
                    this.reportForm.controls.contract?.invalid)
            ) {
                draftError = 'Necesitas al menos descripción, fecha y contrato / centro para guardar como borrador';
            }
            const snackBarText = isDraft ? draftError : '¡Hay errores en el formulario!';
            this.sending = false;
            this._snackBar.open(snackBarText, 'X', {
                duration: 3000,
                panelClass: ['error-snackbar']
            });
        }
    }

    setSelectFormValue(controlName: string, value: string): void {
        this.reportForm.controls[controlName].setValue(value);
    }

    getDefaultValue(type: 'contract' | 'activity_incident' | 'risk_incident' | 'work_hour'): OptionInteface | null {
        let defaultOption: OptionInteface | null;
        switch (type) {
            case 'contract':
                defaultOption = this.contractOptions.find(option => option.key === this.incident.contract.id);
                break;

            case 'activity_incident':
                if (this.incident.activity_incident && this.incident.activity_incident.id) {
                    defaultOption = this.activityListOptions.find(
                        option => option.key === this.incident.activity_incident.id
                    );
                } else {
                    defaultOption = null;
                }
                break;

            case 'risk_incident':
                if (this.incident.risk_incident && this.incident.risk_incident.id) {
                    defaultOption = this.riskIncidentsOptions.find(
                        option => option.key === this.incident.risk_incident.id
                    );
                } else {
                    defaultOption = null;
                }
                break;

            default:
                defaultOption = this.workDayHourOptions.find(option => option.key === this.incident.work_day_hour);
                break;
        }
        return defaultOption;
    }

    getMultipleDefaultValue(type: 'inmediate_causes' | 'root_causes'): OptionInteface[] {
        let defaultValues: OptionInteface[] = [];
        switch (type) {
            case 'inmediate_causes':
                defaultValues = this.incident.inmediate_causes.map(cause => {
                    return this.inmediateCausesOptions.find(option => option.key === cause.id);
                });
                break;

            default:
                defaultValues = this.incident.root_causes.map(cause => {
                    return this.rootCausesOptions.find(option => option.key === cause.id);
                });
                break;
        }
        return defaultValues;
    }

    getSelectsData(): void {
        this.reportService.getNewReportSelect('riskIncidents', true).subscribe(
            (response: any) => {
                this.riskIncidentsOptions = response
                    .filter(res => res.is_hipo === false)
                    .map(option => {
                        return { key: option.id, value: option.name };
                    });
            },
            error => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );

        this.reportService.getNewReportSelect('contract').subscribe(
            (response: any) => {
                this.contractOptions = response.map(option => {
                    return { key: option.id, value: option.name };
                });
            },
            error => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );

        this.reportService.getNewReportSelect('activityList').subscribe(
            (response: any) => {
                this.activityListOptions = response.map(option => {
                    return { key: option.id, value: option.name };
                });
            },
            error => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );

        this.reportService.getNewReportSelect('workDayHour').subscribe(
            (response: any) => {
                this.workDayHourOptions = response.map(option => {
                    return { key: option[0], value: option[1] };
                });
                if (this.incident) {
                    const optionFound = this.workDayHourOptions.find(
                        option => option.value === this.incident.work_day_hour
                    );
                    this.incident.work_day_hour = optionFound?.key.toString();
                    this.fillForm();
                }
            },
            error => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );

        this.reportService.getNewReportSelect('rootCauses').subscribe(
            (response: any) => {
                this.rootCausesOptions = response.map(option => {
                    return { key: option.id, value: option.name };
                });
            },
            error => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );

        this.reportService.getNewReportSelect('inmediateCauses').subscribe(
            (response: any) => {
                this.inmediateCausesOptions = response.map(option => {
                    return { key: option.id, value: option.name };
                });
            },
            error => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );
    }

    onFilesSelected(files: File[], type: 'investigation' | 'actions' | 'description' = 'investigation'): void {
        let arrayFiles = Array.from(files);
        switch (type) {
            case 'actions':
                arrayFiles.map(f => this.actionsFiles.push(f));
                this.reportForm.controls['preventive_actions_files'].setValue('1'); // hay archivos
                break;

            case 'description':
                arrayFiles.map(f => this.descriptionFiles.push(f));
                this.reportForm.controls['description_files'].setValue('1');
                break;

            default:
                arrayFiles.map(f => this.reportFiles.push(f));
                this.reportForm.controls['investigation_files'].setValue('1');
        }
    }

    removeFile(file: File, type: 'investigation' | 'actions' | 'description' = 'investigation'): void {
        switch (type) {
            case 'actions':
                let fileActionsIndex = this.actionsFiles.indexOf(file);
                this.actionsFiles.splice(fileActionsIndex, 1);
                if (this.actionsFiles.length === 0) {
                    this.reportForm.controls['preventive_actions_files'].setValue(null); // no hay archivos
                }
                break;

            case 'description':
                let fileDescriptionIndex = this.descriptionFiles.indexOf(file);
                this.descriptionFiles.splice(fileDescriptionIndex, 1);
                if (this.descriptionFiles.length === 0) {
                    this.reportForm.controls['description_files'].setValue(null);
                }
                break;

            default:
                let fileReportIndex = this.reportFiles.indexOf(file);
                this.reportFiles.splice(fileReportIndex, 1);
                if (this.reportFiles.length === 0) {
                    this.reportForm.controls['investigation_files'].setValue(null);
                }
        }
    }

    removeExistingFile(fileIndex: number, type: 'investigation' | 'actions' | 'description' = 'investigation'): void {
        switch (type) {
            case 'actions':
                this.existingActionsFiles.splice(fileIndex, 1);
                if (!this.existingActionsFiles.length) {
                    this.reportForm.controls['preventive_actions_files'].setValue(null);
                }
                break;

            case 'description':
                this.existingDescriptionFiles.splice(fileIndex, 1);
                if (!this.existingDescriptionFiles.length) {
                    this.reportForm.controls['description_files'].setValue(null);
                }
                break;

            default:
                this.existingReportFiles.splice(fileIndex, 1);
                if (!this.existingReportFiles.length) {
                    this.reportForm.controls['investigation_files'].setValue(null);
                }
        }
    }

    goBackToReport(incident?: number): void {
        if (incident) {
            this.router.navigate(['/report', incident]);
        } else {
            this.router.navigate(['/team']);
        }
    }

    loadSettings(): void {
        this.reportService.loadSettings().subscribe(
            (response: APIAppSettingI[]) => {
                this.setIncidentTypesTooltips(response);
            },
            error => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );
    }

    setIncidentTypesTooltips(response: APIAppSettingI[]): void {
        const incidentSetting = response.find(setting => setting.type === 'incident_desciption');
        const riskSetting = response.find(setting => setting.type === 'risk_situation_desciption');
        if (incidentSetting) {
            this.incidentTypes[0].tooltipData = {
                label: incidentSetting.value,
                placement: 'bottom'
            }
        }
        if (riskSetting) {
            this.incidentTypes[1].tooltipData = {
                label: riskSetting.value,
                placement: 'bottom'
            }
        }
    }

    selectIncidentType(option: RadioButton): void {
        this.selectedTypeOption = option;
    }

    nextStep(): void {
        if (this.selectedTypeOption) {
            this.reportForm.patchValue({ incident_type: this.selectedTypeOption.key });
            this.manageFormValidators();
        }
    }

    backStep(): void {
        this.reportForm.patchValue({ incident_type: null });
        this.manageFormValidators();
    }

    manageFormValidators(): void {
        const { incident_type } = this.reportForm.getRawValue();
        if (incident_type === INCIDENT_TYPE_MAP_KEY.INCIDENT) {
            this.reportForm.get('risk_incident').setValidators(Validators.required);
            this.reportForm.get('investigation_files').setValidators(Validators.required);
            this.reportForm.get('inmediate_causes').setValidators(Validators.required);
            this.reportForm.get('root_causes').setValidators(Validators.required);
        } else {
            this.reportForm.get('risk_incident').clearValidators();
            this.reportForm.get('investigation_files').clearValidators();
            this.reportForm.get('inmediate_causes').clearValidators();
            this.reportForm.get('root_causes').clearValidators();
        }
    }
}
