import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Season, SeasonApiI, SeasonDataI } from '@models/season.model';
import { getCall } from '@services/api/callHelper.service';
import { HeadersService } from '@services/api/headers.service';

@Injectable({
    providedIn: 'root'
})
export class SeasonsService extends HeadersService {
    public seasonFinishDate: Date | null = null;
    public seasonStartDate: Date | null = null;
    public seasonFinishRefereeDate: Date | null = null;

    constructor(public http: HttpClient) {
        super(http);
    }

    /** Returns finishDate, startDate and seasonFinishRefereeDate from current season. If they
     * are not yet stored, calls backend to get them.
     */
    async getSeasonDates(): Promise<SeasonDataI> {
        if (!this.seasonFinishDate || !this.seasonStartDate || !this.seasonFinishRefereeDate) {
            // call backend
            const { finishDate, startDate, finishRefereeDate } = await this.getBackendSeason();
            if (finishDate && startDate && finishRefereeDate ) {
                this.setData(finishDate, startDate, finishRefereeDate);
            }
        }
        return {
            finishDate: this.seasonFinishDate,
            startDate: this.seasonStartDate,
            finishRefereeDate: this.seasonFinishRefereeDate
        };
    }

    setData(finishDate: Date, startDate: Date, finishRefereeDate: Date): void {
        this.seasonStartDate = startDate;
        this.seasonFinishDate = finishDate;
        this.seasonFinishRefereeDate = finishRefereeDate;
    }

    getBackendSeason(): any {
        return new Promise((resolve, reject) => {
            const url = getCall('currentSeason', null, {});
            this.http.get(url, this.getHeaders()).subscribe(
                (response: SeasonApiI) => {
                    const season = new Season(response);
                    const { finishDate, startDate, finishRefereeDate } = season;
                    resolve({ finishDate: finishDate, startDate: startDate, finishRefereeDate });
                },
                error => {
                    reject({});
                }
            );
        });
    }
}
