interface faqQuestion {
    title: string;
    answer: string;
    closed: boolean;
}

export interface faqCategory {
    title: string;
    questions: faqQuestion[];
}

const FAQS: faqCategory[] = [
    {
        title: 'General',
        questions: [
            {
                title: '¿En qué consiste el juego?',
                answer: `
            <p>El objetivo del colectivo de gestores es marcar el mayor número de goles, escalar posiciones en el ranking y subir su estadio al máximo nivel.</p>
            <p>Para marcar goles, deberán reportar incidentes a través de la plataforma, que serán evaluados por el equipo arbitral.</p>
            <p>Al finalizar la temporada, se entregarán premios para los ganadores.</p>
            <p>La correcta identificación y análisis de los incidentes y situaciones de riesgo nos permite actuar de forma proactiva, tomando medidas preventivas para anticiparnos y preservar la salud y seguridad de nuestro personal.</p>
            `,
                closed: true,
            },
            {
                title: '¿Cómo accedo a la plataforma?',
                answer: `
            <p>Para acceder a la plataforma, deberás buscar en tu navegador móvil o web laligadelaseguridad.com y acceder con el usuario y contraseña que te ha sido asignado y enviado por correo electrónico.</p>
            `,
                closed: true
            },
            {
                title: '¿Qué pasa si no recuerdo mi contraseña para acceder?',
                answer: `
            <p>Ahora puedes recuperar tu contraseña pulsando sobre el enlace que aparece en la pantalla de inicio de sesión con el texto: "Si no recuerdas tu contraseña, puedes recuperarla haciendo click <i><u>aquí</u></i>". También puedes escribir al correo de soporte para que te envíen una nueva contraseña. El contacto es: <a href="mailto:soportelaliga@wearecactus.com">soportelaliga@wearecactus.com</a></p>
            `,
                closed: true,
            },
            {
                title: 'Pantalla principal',
                answer:
            `<p>En tu pantalla de inicio verás cuatro módulos:</p>
            <ul>
                <li>El <b>estadio</b>:
                    <ul>
                        <li>En <b>jugadores/as</b> y <b>entrenadores/as</b>: representa a tu <b>equipo</b>. Si pulsas sobre el nombre de tu equipo accederás a la “alineación” de tu equipo (es decir, todas las personas que forman tu equipo) y a tu entrenador/a.</li>
                        <li>Para los <b>árbritos</b> representa tu <b>bandeja de entrada</b>. Si pulsas sobre la bandeja de entrada, accederás al listado de incidentes reportados por los gestores/as que están pendientes de evaluar por los técnicos de SSL. Solo podrás ver los incidentes que se corresponden a tu zona.</li>
                    </ul>
                </li>
                <li>La <b>pantalla de televisión</b> gigante representa el “Minuto deportivo”. Si pulsas sobre “Reportajes”, accederás a “Minuto deportivo”, un espacio donde cada quince días, dos reporteros futbolísticos comentarán las “mejores jugadas” de la jornada y cómo avanzan los equipos en la clasificación.</li>
                <li>La <b>biblioteca</b> representa el conocimiento. Si pulsas sobre “Repositorio”, accederás al repositorio, donde estarán almacenados todos los incidentes ya revisados por los técnicos de SSL (válidos y no válidos) y podrás consultar la puntuación de cada uno.</li>
                <li>El <b>edificio</b> representa el ránking. Si pulsas sobre “Ranking”, accederás a los diferentes rankings activos dentro de la plataforma: el ranking de equipos y el ranking de pichichis. Desde el ranking de equipos, puedes entrar a ver el estadio de cualquier equipo, así como su alineación.</li>
            </ul>
            `,
                closed: true,
            },
            {
                title: '¿Qué tipos de usuario pueden acceder a la herramienta?',
                answer: `
            <p>Existen 4 tipos de usuarios en la plataforma.</p>
            <ul>
                <li>Jugadores/as (gestores/as y gerentes): Reportan incidentes a cambio de goles para su equipo. </li>
                <li>Entrenadores/as (delegados/as): Las personas que tienen a su cargo un grupo de gestores.</li>
                <li>Equipo de arbitraje (équipo técnico de SSL y Dirección regional): Evalúan los incidentes</li>
                <li>Animadores/as (director/a de negocio): Supervisan el avance del juego y pueden animar o felicitar a los equipos a través de las publicaciones del minuto deportivo.</li>
            </ul>
            `,
                closed: true,
            },
            {
                title: '¿Cómo se organizan los jugadores/as dentro de la herramienta?',
                answer: `
            <p>Habrá un equipo por cada delegación, a excepción de las delegaciones que no dispongan de Delegado, que habrá un equipo por gerencia.</p>
            `,
                closed: true,
            },
            {
                title: 'Si cada equipo tiene una cantidad distinta de miembros, ¿los equipos con más miembros tienen ventaja? ',
                answer: `
            <p>No. Todos los equipos tienen la misma oportunidad de ganar. El juego se adapta automáticamente para que los goles recibidos por sus incidentes sea proporcional al tamaño de su equipo, y que de esta forma todos los equipos compitan en la misma igualdad de condiciones.</p>
            `,
                closed: true,
            },
            {
                title: '¿Cómo subo de nivel mi estadio?',
                answer: `
            <p>El estadio crece automáticamente cuando tu equipo consigue los goles necesarios.</p>
            <p>La cantidad de goles necesarios para subir de nivel tu estadio dependerá de la cantidad de miembros que tenga tu equipo.</p>
            <table class="faq-table-colored">
                <tr>
                    <th class="colored">Nivel</th>
                    <th class="colored">Incidentes reportados</th>
                    <th class="colored-yellow">Nivel</th>
                    <th class="colored-yellow">Incidentes reportados</th>
                </tr>
                <tr> <td class="colored">1</td>        <td class="colored">0</td>
                     <td class="colored-yellow">6</td> <td class="colored-yellow">7*(nº miembros)</td>
                </tr>
                <tr> <td class="colored">2</td>        <td class="colored">0,5*(nº miembros)</td>
                     <td class="colored-yellow">7</td> <td class="colored-yellow">10*(nº miembros)</td>
                </tr>
                <tr> <td class="colored">3</td>        <td class="colored">1*(nº miembros)</td>
                     <td class="colored-yellow">8</td> <td class="colored-yellow">15*(nº miembros)</td>
                </tr>
                <tr> <td class="colored">4</td>        <td class="colored">3*(nº miembros)</td>
                     <td class="colored-yellow">9</td> <td class="colored-yellow">20*(nº miembros)</td>
                </tr>
                <tr> <td class="colored">5</td>        <td class="colored">5*(nº miembros)</td>
                     <td class="colored-yellow">10</td> <td class="colored-yellow">25*(nº miembros)</td>
                </tr>
            </table>
            `,
                closed: true,
            },
            {
                title: '¿Cómo puede un equipo subir de división? ¿Puede bajar de división?',
                answer: `
            <p>Existen 3 divisiones. Mientras que el nivel de tu estadio es fijo, las divisiones sí son variables y tu equipo puede subir o bajar de división según la cantidad de goles que acumule en ese momento.</p>
            <p>La división se representa con una estrella debajo del nombre del equipo en algunas pantallas.</p>
            <div class="imgQuestion-01"></div>
            <table class="faq-table">
                <tr> <td>1ª división</td> <td>Los 9 equipos que ocupen las primeras posiciones del ranking</td> </tr>
                <tr> <td>2ª división</td> <td>Los 9 siguientes equipos que ocupen el ranking</td> </tr>
                <tr> <td>3ª división</td> <td>Los 9 siguientes equipos que ocupen el ranking</td> </tr>
            </table>
            `,
                closed: true,
            },
            {
                title: '¿Qué tipos de ranking hay dentro de la herramienta?',
                answer: `
            <p>En la plataforma hay tres tipos de ranking:</p>
            <ul>
                <li><b>Ranking general</b>: es un ranking que muestra el número de <b>goles acumulados</b> por equipo. Este ranking se puede filtrar por división.</li>
                <li><b>Ranking mensual</b>: es un ranking que muestra el número total de <b>goles obtenidos</b> por equipo durante un mes y se reinicia todos los días 1 del mes.</li>
                <li><b>Ranking de pichichis</b>: es un ranking que muestra el número de <b>puntos obtenidos</b> por jugador/a.</li>
            </ul>
            `,
                closed: true,
            },
            {
                title: 'Quiero reportar un incidente pero todavía no puedo completar algunos campos como el de evidencia de cierre o acciones preventivas. ¿Qué debo hacer?',
                answer: `
            <p>Los incidentes pueden ser guardados en borradores.</p>
            <p>Para hacerlo, solo tienes que rellenar obligatoriamente tres campos (Contrato/Centro, fecha del incidente (hemos ampliado la fecha hasta 15 días antes) y descripción del incidente) y pulsar sobre "Guardar en borradores".</p>
            <p>Además, un incidente reportado puede ser devuelto a borrador por el árbitro/a.</p>
            `,
                closed: true,
            },
            {
                title: '¿Dónde encuentro mis borradores?',
                answer: `
            <p>Podrás encontrar todos tus incidentes en borradores dentro de Repositorio > Ver: Míos > Borradores.</p>
            <div class="imgQuestion-02"></div>
            <p>Estos serán editables hasta 15 días después de la fecha que seleccionaste como la fecha del incidente (no cuando lo diste de alta). Después, si quieres enviar alguno a revisión, tendrás que ponerte en contacto con soporte. El contacto es: <a href="mailto:soportelaliga@wearecactus.com">soportelaliga@wearecactus.com</a></p>
            `,
                closed: true
            },
            {
                title: 'Un equipo tiene la misma cantidad de goles que el mío, pero en el ranking va por delante. ¿Por qué ocurre esto?',
                answer: `
            <p>En el caso de empate entre dos equipos, el equipo con menor número de miembros iría por delante. Y en el caso de empate, entre dos equipos con el mismo número de miembros, el equipo con mayor número de incidentes con una puntuación por encima de 16 puntos.</p>
            `,
                closed: true,
            },
            {
                title: 'Acabo de reportar un incidente, ¿qué criterios se utilizan para puntuarlo?',
                answer: `
            <p>Las personas con el rol de árbitro/a evaluarán un incidente y otorgarán una cantidad de puntos al incidente dependiendo de los siguientes criterios:</p>
            <p>Los campos que evalúa el equipo arbitral son los siguientes:</p>
            <ul>
                <li><b>¿Es válido este incidente?</b> 1 (sí) - 0 puntos (no).</li>
                <li><b>¿Situación de riesgo o incidente?</b> 0 (situación de riesgo) - 3 puntos (incidente).</li>
                <li><b>Entrevista personal a implicados</b> 0 (sin entrevista) - 3 puntos (entrevista completa).</li>
                <li><b>Claridad de la explicación</b> 0 (mal redactada, sin detalles) - 5 puntos (bien redactada, con detalles).</li>
                <li><b>Calidad del soporte gráfico:</b> 0 (sin soporte gráfico) - 3 puntos (soporte gráfico completo).</li>
            </ul>
            <p>Los campos que evalúa el/la responsable de zona son los siguientes:</p>
            <ul>
                <li><b>Correcta identificación de las causas:</b> 0 (sin identificar o mal identificadas) - 5 puntos (causas inmediatas y raíz bien identificadas).</li>
                <li><b>Evidencia de cierre de acciones:</b> 0 (acciones sin cerrar) - 5 puntos (todas las acciones cerradas).</li>
                <li><b>Realizada reunión de inicio y meta:</b> 0 (no) - 1 punto (sí).</li>
            </ul>
            <p>Los campos que evalúa el/la director/a regional son los siguientes:</p>
            <ul>
                <li><b>Calidad del incidente y análisis:</b> 0 (mala redacción, análisis incorrecto) - 3 puntos (correcta redacción, identificación de causas y acciones relevantes).</li>
            </ul>
            `,
                closed: true,
            },
            {
                title: '¿Qué diferencia hay entre goles y puntos en un incidente?',
                answer: `
            <p>Los puntos es la puntuación que recibe un incidente según determinados criterios de evaluación. Estos puntos se transforman en goles. La cantidad de goles que recibirá un equipo dependerá de la cantidad de miembros del equipo.</p>
            <p>Si un equipo integrado por 5 personas y otro por 10 personas reportan exactamente el mismo incidente, esos dos incidentes recibirán la misma cantidad de puntos. Pero el equipo con 5 personas recibiría más goles por el mismo incidente para poder tener las mismas oportunidades de ganar que el equipo con 10 personas (que recibiría la mitad de goles por el mismo incidente).</p>
            `,
                closed: true,
            },
            {
                title: 'Mi incidente ha sido solo evaluado parcialmente. ¿Qué ocurre?',
                answer: `
            <p>Algunos incidentes deben de ser revisados por más de un árbitro/a. Pero para no hacerte esperar demasiado, recibirás los goles a medida que vayan evaluándose hasta que se complete al 100%.</p>
            `,
                closed: true,
            },
            {
                title: '¿Qué es el repositorio?',
                answer: `
            <p>El repositorio es un espacio donde quedan registrados todos los incidentes revisados (tanto los incidentes válidos como los rechazados) y donde cualquier persona con rol de jugador/a puede consultar los incidentes de su zona, independientemente de quien lo haya publicado. </p>
            `,
                closed: true,
            },
            {
                title: '¿Qué es el Minuto Deportivo?',
                answer: `
            <p>Minuto deportivo es el espacio donde mensualmente, el equipo de reporteros Nieves y Roberto harán un breve resumen a modo de informativo deportivo con la información destacada sobre la competición, entre las que se encuentran:</p>
            <ul>
                <li>TOP 3 Equipos de cada división (en goles) </li>
                <li>TOP 3 Estadios de cada división (estadio)</li>
                <li>TOP 3 pichichis</li>
                <li>Subidas o bajadas destacadas en cada división</li>
                <li>Mejor jugada de cada división (incidencia con mejor puntuación). </li>
            </ul>
            `,
                closed: true,
            },
            {
                title: '¿Qué debo hacer para que me mencionen a mí o a mi equipo en el Minuto Deportivo?',
                answer: `
            <p>¡Destacar! Automáticamente detectaremos los hechos más noticiables, así que no debes solicitar que te incluyan en estos resúmenes. Encárgate de destacar, y ellos se encargarán del resto. </p>
            `,
                closed: true,
            },
            {
                title: '¿Qué pasa si no quiero recibir en mi correo electrónico las notificaciones del minuto deportivo o cualquier otra notificación?',
                answer: `
            <p>Ponte en contacto con el administrador de la plataforma para que desactiven tus notificaciones al correo electrónico. El contacto es: <a href="mailto:soportelaliga@wearecactus.com">soportelaliga@wearecactus.com</a></p>
            `,
                closed: true,
            },
            {
                title: 'Tengo un problema técnico, ¿con quién debería contactar?',
                answer: `
            <p>Ponte en contacto con el equipo administrador de la plataforma. El contacto es: <a href="mailto:soportelaliga@wearecactus.com">soportelaliga@wearecactus.com</a></p>
            `,
                closed: true,
            },
        ],
    },
];

export { FAQS };
