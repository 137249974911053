<div #TooltipElement class="tooltip-container">
    <div class="tooltip-container__content-wrapper">
        <ng-container *ngIf="tooltipData?.label">
            <ng-container *ngIf="tooltipData.useHtml; else DefaultHtml">
                <div class="content__html" [innerHtml]="tooltipData.label | safeHtml"></div>
            </ng-container>
            <ng-template #DefaultHtml>
                <span class="content__label">{{ tooltipData.label }}</span>
            </ng-template>
        </ng-container>
    </div>
    <div class="tooltip-container__arrow"></div>
</div>
