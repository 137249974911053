import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { SportsMinuteService } from '@services/api/sports-minute/sports-minute.service';
import { NEW_REPORTERS_ASSETS_PATH, NewsModel, REPORTERS_ASSETS_PATH } from '@models/sports_minute';
import { Router } from '@angular/router';

@Component({
    selector: 'app-sport-minute-details',
    templateUrl: './sport-minute-details.component.html',
    styleUrls: ['./sport-minute-details.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class SportMinuteDetailsComponent implements OnInit {
    public news: NewsModel;
    public currentSlide: number = 0;

    constructor(private sportsMinuteservice: SportsMinuteService, private router: Router) {}

    ngOnInit(): void {
        if (!this.sportsMinuteservice.selectedNews) {
            this.router.navigateByUrl('/sports-minute');
        }
        this.news = this.sportsMinuteservice.selectedNews;
    }

    get imageUrl() {
        if (this.news) {
            const { reporter, facial_expresion } =
                this.news.slides[this.currentSlide];

            const path = reporter.includes('new')
                ? NEW_REPORTERS_ASSETS_PATH
                : REPORTERS_ASSETS_PATH;

            const imageName = `${reporter}-${facial_expresion}`;

            return `${path}${imageName}.png`;
        } else {
            return '';
        }
    }

    clickNext(): void {
        if (this.news.slides.length - 1 > this.currentSlide) {
            this.currentSlide += 1;
        } else {
            this.router.navigateByUrl('/sports-minute');
        }
    }
}
