import { Onboarding } from '@models/onboarding.model';

export const onboardingPlayer: Onboarding = new Onboarding([
    {
        title: 'Reporta',
        imageUrl: 'assets/images/icons/reportar.svg',
        description:
            '¡Bienvenidos y bienvenidas a la Liga de la Seguridad! Reporta incidentes para marcar goles',
        buttonText: 'Siguiente',
    },
    {
        title: 'Marca goles',
        imageUrl: 'assets/images/onboarding/balones.png',
        description:
            'Cuanta más calidad tengan vuestros reportes de incidentes, más goles marcaréis',
        buttonText: 'Siguiente',
    },
    {
        title: 'Mejora tu estadio',
        imageUrl: 'assets/images/onboarding/stadium.png',
        description:
            'Si tú y tu equipo marcáis goles, subiréis de nivel vuestro estadio y mejoraréis en el ranking',
        buttonText: 'Siguiente',
    },
    {
        title: 'Sé una estrella',
        imageUrl: 'assets/images/onboarding/reporteros.png',
        description:
            'Cada mes, Nieves y Roberto cubrirán las mejores jugadas y las novedades más destacadas',
        buttonText: '¡A jugar!',
    },
]);

export const onboardingReferee: Onboarding = new Onboarding([
    {
        title: 'Bienvenida',
        imageUrl: 'assets/images/onboarding/silbato.svg',
        description:
            '¡Os damos la bienvenida a la Liga de la Seguridad, equipo de arbitraje!',
        buttonText: 'Siguiente',
    },
    {
        title: 'Equipos reportan incidentes',
        imageUrl: 'assets/images/icons/reportar.svg',
        description:
            'Los equipos reportan incidentes para marcar goles. Cuanta más calidad tengan los reportes, más goles marcarán.',
        buttonText: 'Siguiente',
    },
    {
        title: 'Marcan goles',
        imageUrl: 'assets/images/onboarding/balones.png',
        description:
            'Vuestra misión es evaluar los incidentes. Los goles los determinarán vuestras evaluaciones.',
        buttonText: 'Siguiente',
    },
    {
        title: 'Suben el nivel de su estadio',
        imageUrl: 'assets/images/onboarding/stadium.png',
        description:
            'Marcar goles les permitirá a los equipos mejorar su estadio, aparecer en los medios y mejorar en el ranking.',
        buttonText: '¡A jugar!',
    },
]);

export const onboardingTrainer: Onboarding = new Onboarding([
    {
        title: 'Bienvenida',
        imageUrl: 'assets/images/icons/reportar.svg',
        description:
            '¡Os damos la bienvenida a la Liga de la Seguridad, entrenadores/as! ',
        buttonText: 'Siguiente',
    },
    {
        title: 'Marcan goles',
        imageUrl: 'assets/images/onboarding/balones.png',
        description:
            'Vuestros equipos reportan incidentes para marcar goles. Cuanta más calidad tengan los reportes, más goles marcarán.',
        buttonText: 'Siguiente',
    },
    {
        title: 'Mejoran sus estadios',
        imageUrl: 'assets/images/onboarding/stadium.png',
        description:
            'Los equipos que marquen muchos goles subirán de nivel sus estadios y mejorarán en el ranking.',
        buttonText: 'Siguiente',
    },
    {
        title: 'Minuto Deportivo',
        imageUrl: 'assets/images/onboarding/reporteros.png',
        description:
            'Cada mes, Nieves y Roberto cubrirán las mejores jugadas y las novedades más destacadas.',
        buttonText: '¡A jugar!',
    },
]);

export const onboardingCheerleader: Onboarding = new Onboarding([
    {
        title: 'Bienvenida',
        imageUrl: 'assets/images/icons/reportar.svg',
        description:
            '¡Os damos la bienvenida a la Liga de la Seguridad, animadores/as!',
        buttonText: 'Siguiente',
    },
    {
        title: 'Marcan goles',
        imageUrl: 'assets/images/onboarding/balones.png',
        description:
            'Los equipos reportan incidentes para marcar goles. Cuanta más calidad tengan los reportes, más goles marcarán.',
        buttonText: 'Siguiente',
    },
    {
        title: 'Mejoran sus estadios',
        imageUrl: 'assets/images/onboarding/stadium.png',
        description:
            'Los equipos que marquen muchos goles subirán de nivel sus estadios y mejorarán en el ranking.',
        buttonText: 'Siguiente',
    },
    {
        title: 'Minuto Deportivo',
        imageUrl: 'assets/images/onboarding/reporteros.png',
        description:
            'Cada mes, Nieves y Roberto cubrirán las mejores jugadas y las novedades más destacadas.',
        buttonText: '¡A jugar!',
    },
]);
