import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { INCIDENT_TYPE_MAP_KEY } from '@components/pages/new-report/new-report.conf';
import { APIAppSettingI } from '@models/app-settings.model';
import { Report } from '@models/report.model';
import { getCall } from '@services/api/callHelper.service';
import { HeadersService } from '@services/api/headers.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class ReportService extends HeadersService {
    constructor(public http: HttpClient) {
        super(http);
    }

    /**
     * Get the options of selects in new-report page
     *
     * @param selectName 'riskIncidents' | 'contract' | 'activityList' | 'workDayHour' | 'rootCauses' | 'inmediateCauses'
     * @returns
     */
    getNewReportSelect(
        selectName: 'riskIncidents' | 'contract' | 'activityList' | 'workDayHour' | 'rootCauses' | 'inmediateCauses',
        isHipo: boolean = false
    ) {
        let params = {};
        if (isHipo) {
            params['is_hipo'] = 'True';
        }
        const url = getCall(selectName, null, params);
        return this.http.get(url, this.getHeaders());
    }

    createIncident(incident: Report, type: 'post' | 'update', incidentId?: number): any {
        const url = type === 'update' ? getCall('incident', incidentId, {}) : getCall('incident', null, {});
        const {
            activity_incident,
            risk_incident,
            incident_date,
            incident_hour,
            location,
            description,
            preventive_action,
            inmediate_causes,
            root_causes,
            is_draft,
            investigation_files,
            preventive_actions_files,
            description_files,
            work_day_hour,
            contract,
            existing_actions_files,
            existing_investigation_files,
            existing_description_files,
            incident_type
        } = incident;
        let incidentData: FormData = new FormData();
        
        if (activity_incident) incidentData.append('activity_incident', activity_incident);
        
        if (incident_date) incidentData.append('incident_date', incident_date);
        
        if (incident_hour) incidentData.append('incident_hour', incident_hour);
        
        if (location) incidentData.append('location', location);
        
        if (description) incidentData.append('description', description);
        
        incidentData.append('is_draft', JSON.stringify(is_draft));
        
        if (preventive_action) incidentData.append('preventive_action', preventive_action);
        
        if (preventive_actions_files) {
            preventive_actions_files.forEach((file, index) => {
                incidentData.append(`preventive_actions_files_${index}`, file);
            });
        }
        incidentData.append('existing_actions_files', JSON.stringify(existing_actions_files));
        
        if (description_files) {
            description_files.forEach((file, index) => {
                incidentData.append(`description_files_${index}`, file);
            });
        }
        incidentData.append('existing_description_files', JSON.stringify(existing_description_files));

        if (work_day_hour) incidentData.append('work_day_hour', work_day_hour);
        
        if (contract) incidentData.append('contract', contract);

        incidentData.append('incident_type', incident_type);

        if (incident_type === INCIDENT_TYPE_MAP_KEY.INCIDENT) {
            if (risk_incident) incidentData.append('risk_incident', risk_incident);
            
            if (investigation_files) {
                investigation_files.forEach((file, index) => {
                    incidentData.append(`investigation_files_${index}`, file);
                });
            }
            incidentData.append('existing_investigation_files', JSON.stringify(existing_investigation_files));
            
            if (inmediate_causes && inmediate_causes.length) {
                incidentData.append('inmediate_causes', JSON.stringify(inmediate_causes));
            }
            
            if (root_causes && root_causes.length)
                {
                    incidentData.append('root_causes', JSON.stringify(root_causes));
                }
        }
        
        return type === 'update'
            ? this.http.patch(url, incidentData, this.getHeaderWOCT())
            : this.http.post(url, incidentData, this.getHeaderWOCT());
    }

    loadSettings(): Observable<APIAppSettingI[]> {
        const url = getCall('appSettings', null, {});
        return this.http.get<APIAppSettingI[]>(url, this.getHeaders());
    }
}
