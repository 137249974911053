import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { APIMedalI } from '@models/medals.model';
import { getCall } from '@services/api/callHelper.service';
import { HeadersService } from '@services/api/headers.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class MedalsService extends HeadersService {

    constructor(public http: HttpClient) {
        super(http)
    }

    getMedals(): Observable<APIMedalI[]> {
        const url = getCall('medals', null, {});

        return this.http.get<APIMedalI[]>(url, this.getHeaders());
    }
}
