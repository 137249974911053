export interface NewsModel {
    id: number;
    title: string;
    content: string;
    created_at: string;
    read: boolean;
    slides: SlideModel[];
    type: string;
}

export interface SlideModel {
    title: string;
    content: string;
    reporter: 'boy' | 'girl';
    facial_expresion: 'tears' | 'happy' | 'super-happy' | 'angry' | 'worried' | 'surprise';
}
export interface ResponseAPI_News {
    count: number;
    next: null | string;
    previous: null | string;
    results: NewsModel[];
}

export const REPORTERS_ASSETS_PATH = 'assets/images/sports-minute/';
export const NEW_REPORTERS_ASSETS_PATH = `${REPORTERS_ASSETS_PATH}new-reporters/`;
