export const MAX_ALLOWED_CAUSE_OPTIONS: number = 3;

export const INCIDENT_TYPE_MAP_VALUE = {
    incident: 'Incidente',
    risk_situation: 'Situación de riesgo',
};

export const INCIDENT_TYPE_MAP_KEY = {
    INCIDENT: 'incident',
    RISK_SITUATION: 'risk_situation',
};

export const INCIDENT_TYPES = [
    {
        key: INCIDENT_TYPE_MAP_KEY.INCIDENT,
        value: INCIDENT_TYPE_MAP_VALUE[INCIDENT_TYPE_MAP_KEY.INCIDENT],
        active: true,
    },
    {
        key: INCIDENT_TYPE_MAP_KEY.RISK_SITUATION,
        value: INCIDENT_TYPE_MAP_VALUE[INCIDENT_TYPE_MAP_KEY.RISK_SITUATION],
        active: false,
    },
];
