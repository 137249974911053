export const TOOLTIP_OFFSET: number = 8;

export type TooltipPlacementT = 'top' | 'right' | 'bottom' | 'left';

export interface TooltipI {
    label: string;
    placement: TooltipPlacementT;
    useHtml?: boolean;
    classes?: string[];
}
