import { Helper } from '@helpers/helper/helper';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { incidentResume } from '@models/incidents-resume.model';
import { Team } from '@models/team.model';
import { AppUser } from '@models/user.model';
import { LoginService } from '@services/api/login/login.service';
import { TeamService } from '@services/api/team/team.service';
import { Location } from '@angular/common';
import { incidentsTemplate } from 'app/providers/store/incident-resume.store';
import { Subscription } from 'rxjs';
import { SeasonsService } from '@services/api/seasons/seasons.service';
import { ApiErrorHandlerService } from '@services/api/error/api-error-handler.service';
import { MEDALS_DEFAULT_ASSET } from '@models/medals.model';

@Component({
    selector: 'app-team-single',
    templateUrl: './team-single.component.html',
    styleUrls: ['./team-single.component.scss']
})
export class TeamSingleComponent implements OnInit, OnDestroy {
    public isMyTeam = false;
    public teamId: number;
    public team: Team;
    public incidents: incidentResume[];
    public user: AppUser;

    // True if is referee home
    public isRefereeHome = false;
    // True is si cheerleader home
    public isCheerleaderHome = false;

    public points: number;
    public totalPoints: number;
    public seasonStartDate: Date | null = null;
    public seasonFinishDate: Date | null = null;
    public showReportButton: boolean = false;

    public totalIncidents: number = 0; //default number incidents [0]
    public totalIncidentsText: string = 'Incidentes válidos';

    private subsUser: Subscription;

    public medalImage: string = MEDALS_DEFAULT_ASSET;

    constructor(
        private route: ActivatedRoute,
        private router: Router,
        private location: Location,
        private teamService: TeamService,
        private loginService: LoginService,
        private seasonService: SeasonsService,
        private helper: Helper,
        private errorService: ApiErrorHandlerService
    ) {}

    ngOnInit(): void {
        this.incidents = incidentsTemplate.map(incident => {
            return { ...incident };
        });
        // isn't user team?
        this.teamId = this.route.snapshot.params.team_id ? this.route.snapshot.params.team_id : null;
        this.isMyTeam = !!!this.teamId; // si en la ruta viene el id del equipo NO ES el equipo del usuario
        this.user = this.loginService.currentAppUserValue;

        if (this.user) {
            this.setUserData();
        }
    }

    ngOnDestroy(): void {
        if (this.subsUser) {
            this.subsUser.unsubscribe();
        }
    }

    setUserData(): void {
        this.subsUser = this.loginService.getUser().subscribe(() => {
            this.user = this.loginService.currentAppUserValue;
        }); // update del user para el número de incidentes

        if (this.user?.profile === 'trainer' && this.isMyTeam) {
            this.isMyTeam = false;
        }
        this.handleIncidentsDetails();

        this.isRefereeHome = this.checkProfileHome('referee');
        this.isCheerleaderHome = this.checkProfileHome('cheerleader');
        if (!this.isCheerleaderHome) {
            this.loadTeam();
        }
    }

    handleIncidentsDetails(): void {
        this.user.all_points_evaluated_questions.map((q: { question: string; points: number; } | { total_incidents: number; }, index) => {
            if ('question' in q && 'points' in q) {
                this.incidents[index].playerScore = q.points;
                this.incidents[index].question = q.question;
            } else {
                this.totalIncidents = q.total_incidents;
            }
        });
    }

    checkProfileHome(profileName: string): boolean {
        return this.user.profile === profileName && this.isMyTeam;
    }

    loadTeam(): void {
        this.teamService.getTeams(this.teamId).subscribe(
            (response: any) => {
                this.team = response?.results ? response.results[0] : response;
                this.loadIncidents(this.team.id);
            },
            error => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );
    }

    loadIncidents(teamId): void {
        this.teamService.getTeams(teamId).subscribe(
            (response: any) => {
                this.points = response.total_incidents;
                this.totalPoints = response.get_next_level_incidents + response.total_incidents;
                if (!this.isMyTeam) {
                    this.team.get_points_users.forEach((question, index: number) => {
                        this.incidents[index].teamScore = question.points ? question.points : 0;
                    });
                }
                this.getSeasonFinishDate();
            },
            error => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );
    }

    async getSeasonFinishDate(): Promise<void> {
        const { finishDate, startDate } = await this.seasonService.getSeasonDates();
        this.seasonFinishDate = finishDate;
        this.seasonStartDate = startDate;
        this.checkReportButton();
    }

    checkReportButton(): void {
        if (this.seasonFinishDate && this.seasonStartDate) {
            // if given date is older than today player can report incident
            this.showReportButton = this.isMyTeam && this.helper.isBetweenDates(this.seasonStartDate, this.seasonFinishDate);
        } else {
            this.showReportButton = false;
        }
    }

    goBack(): void {
        this.location.back();
    }

    goMedals(): void {
        this.router.navigate([ 'medals' ]);
    }
}
