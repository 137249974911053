<div class="container page-with-top-bar gradient pb-md h-100">
    <div class="row" *ngIf="team || isCheerleaderHome">
        <div class="ferro-logo-big-wrapper text-center mb-sm mt-sm" *ngIf="isRefereeHome || isCheerleaderHome">
            <img class="img-max-width" src="assets/images/logos/logo-liga-negro.png" alt="" />
        </div>
        <div *ngIf="isCheerleaderHome" class="mt-5 mb-5 text-center cheerleader-text w-100">
            <p class="cheerleader-text__main">Número de incidentes publicados en la plataforma:</p>
            <p class="cheerleader-text__number">{{ user.last_id }}</p>
            <p class="cheerleader-text__info" *ngIf="user.profile !== 'cheerleader'">
                ¡Atención! Este es el número total de incidentes publicados, puede no corresponder con el número total
                de incidentes en el repositorio porque algunos están pendientes de evaluar por los árbitros/as.
            </p>
        </div>
        <div
            class="col-12 col-md-6 city-wrapper"
            [ngClass]="{
                'col-md-6': !isCheerleaderHome && !isRefereeHome,
                'col-md-12': isRefereeHome || isCheerleaderHome
            }"
        >
            <div class="arrow-back pointer" *ngIf="!isMyTeam" (click)="goBack()"></div>
            <app-city
                *ngIf="team"
                [isCheerleaderHome]="isCheerleaderHome"
                [isRefereeHome]="isRefereeHome"
                [level]="team.level"
                [team]="team"
            ></app-city>
            <app-city
                *ngIf="!team"
                [isCheerleaderHome]="isCheerleaderHome"
                [isRefereeHome]="isRefereeHome"
                [level]="3"
                [team]="team"
            ></app-city>
            <div class="col-12" *ngIf="user && user?.profile === 'player'">
                <div class="medals-container">
                    <div class="white-modal">
                        <div class="section pointer" (click)="goMedals()">
                            <h3 class="white-modal__title">
                                <img [src]="medalImage" alt="Medal image">
                                Medallas
                            </h3>
                            <p class="white-modal__sub-title">
                                Has obtenido <strong>{{user?.medals?.length}}</strong> {{user?.medals?.length === 1 ? 'medalla' : 'medallas'}}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="col-12 col-md-6" *ngIf="!isRefereeHome && !isCheerleaderHome">
            <div class="white-modal">
                <div class="section">
                    <h3 class="white-modal__title">Estadio en nivel {{ team.level }}.</h3>
                    <p class="white-modal__sub-title">Incidentes del equipo para el próximo nivel:</p>
                    <app-progress-bar [userPoints]="points" [nextLevelPonits]="totalPoints"></app-progress-bar>
                </div>

                <div class="section" *ngIf="incidents">
                    <h3 *ngIf="user.profile !== 'player' || !isMyTeam" class="white-modal__title">
                        {{ team.total_goals }} goles marcados
                    </h3>
                    <h3 *ngIf="user.profile === 'player' && isMyTeam" class="white-modal__title">
                        Has marcado {{ user.total_goals || '0' }} de los {{ team.total_goals }} goles del equipo
                    </h3>
                    <div class="incidents">
                        <div class="incident no-bg mb-xs">
                            <div class="name" [ngClass]="{ large: !isMyTeam }">
                                {{
                                    isMyTeam
                                        ? 'Puntuaciones de tus incidentes'
                                        : 'Puntuaciones totales de todos los miembros del equipo'
                                }}
                            </div>
                            <div class="p-score d-flex justify-content-center" *ngIf="isMyTeam">
                                <div class="user-avatar" [style.background-image]="'url(' + user.avatar + ')'"></div>
                            </div>
                        </div>
                        <div class="incident mb-xs" *ngFor="let incident of incidents">
                            <img
                                class="incident__icon"
                                [src]="'assets/images/icons/' + incident.icon + '.svg'"
                                [alt]="incident.icon"
                            />
                            <div class="name bold" [ngClass]="{ large: !isMyTeam }">{{ incident.sortQuestion }}</div>
                            <div class="p-score" *ngIf="isMyTeam">{{ incident.playerScore }}</div>
                            <div class="t-score" *ngIf="!isMyTeam">{{ incident.teamScore }}</div>
                        </div>
                        <!-- TOTAL INCIDENTS POINTS -->
                        <div class="incident mb-xs">
                            <div class="icon-round incident__icon completed"></div>
                            <div class="name bold" [ngClass]="{ large: !isMyTeam }">{{ totalIncidentsText }}</div>
                            <div class="p-score" *ngIf="isMyTeam">{{ totalIncidents }}</div>
                        </div>
                    </div>
                </div>

                <div class="text-center" *ngIf="showReportButton">
                    <app-button
                        [text]="'Reportar incidente'"
                        [block]="true"
                        [icon]="'reportar'"
                        [routerLink]="['/new-report']"
                    ></app-button>
                </div>
            </div>
        </div>
    </div>
</div>
