export const MEDALS_INACTIVE_ASSET: string = 'assets/images/medals/inactive.png';
export const MEDALS_GOLD_ASSET: string = 'assets/images/medals/gold.png';
export const MEDALS_SILVER_ASSET: string = 'assets/images/medals/silver.png';
export const MEDALS_BRONZE_ASSET: string = 'assets/images/medals/bronze.png';
export const MEDALS_DEFAULT_ASSET: string = MEDALS_GOLD_ASSET;

export interface APIMedalI {
    key: string;
    name: string;
    picture: string | null;
    medal_types: APIMedalTypeI[];
}

export type APIMedalTypeT = 'bronze' | 'silver' | 'gold';

export interface APIMedalTypeI {
    description: string;
    id: number;
    is_completed: boolean;
    number_of_tickets: number;
    percent_users: number;
    type: APIMedalTypeT;
}

export interface APIMedalUserI {
    created_at: Date;
    id: number;
    medal: APIMedalI;
    medal_type: APIMedalTypeI[];
    updated_at: Date;
}

export class Medal {
    public name: string;
    public image: string | null;
    public types: MedalType[];
    public isCompleted: boolean;

    constructor(private input: APIMedalI) {
        const {
            name,
            picture,
            medal_types
        } = this.input;
        this.name = name;
        this.image = picture || MEDALS_DEFAULT_ASSET;
        this.types = medal_types.map(type => new MedalType(type));
        this.isCompleted = this.types.every(type => type.isCompleted);
    }
}

export class MedalType {
    public isCompleted: boolean;
    public image: string;
    public type: APIMedalTypeT;
    public description: string;

    constructor(private input: APIMedalTypeI) {
        const {
            is_completed,
            type,
            description
        } = this.input;
        this.isCompleted = is_completed;
        this.type = type;
        this.image = this.getImage();
        this.description = description;
    }

    getImage(): string {
        if (!this.isCompleted) {
            return MEDALS_INACTIVE_ASSET;
        }

        switch (this.type) {
            case 'bronze':
                return MEDALS_BRONZE_ASSET;

            case 'silver':
                return MEDALS_SILVER_ASSET;

            case 'gold':
                return MEDALS_GOLD_ASSET;
        }
    }
}
