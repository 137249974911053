<div class="bg futball-field h-100">
    <div class="container page-with-top-bar pb-md full-height">
        <div class="medal-view-header">
            <div class="medal-view-header__wrapper">
                <img [src]="defaultImage" class="header-wrapper__image">
                <span class="header-wrapper__title">Medallas</span>
            </div>
            <div class="pointer">
                <img src="assets/images/icons/close-icon.svg" [routerLink]="['/team']">
            </div>
        </div>

        <div class="two-cols-desktop mt-5">
            <div
                *ngFor="let medal of medals"
                class="medal-list-container bordered-section column px-3"
            >
                <div class="medal-list-container__medal-wrapper">
                    <div class="medal-wrapper__header-container">
                        <img [src]="medal.image" alt="Medal image" class="medal-header__image">
                        <p class="medal-header__name">{{ medal.name }}</p>
                        <img
                            *ngIf="medal.isCompleted"
                            [src]="defaultImage"
                            alt="Completed medal image"
                            class="medal-header__completed"
                        >
                    </div>
                    <div class="medal-wrapper__body-container">
                        <div class="medal-type" *ngFor="let medalType of medal.types">
                            <img [src]="medalType.image" alt="Medal type image" class="medal-type__image">
                            <span class="medal-type__description">{{ medalType.description }}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
