import { TooltipI } from '@models/tooltip.model';
import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';

@Component({
    selector: 'app-tooltip',
    templateUrl: './tooltip.component.html',
    styleUrls: ['./tooltip.component.scss'],
})
export class TooltipComponent implements OnInit {
    @Input() tooltipData?: TooltipI;

    @ViewChild('TooltipElement', { static: true }) tooltipElement!: ElementRef;

    ngOnInit(): void {
        if (this.tooltipData?.useHtml && this.tooltipData.label) {
            this.parseLabel();
        }
    }

    parseLabel(): void {
        this.tooltipData.label = this.tooltipData.label.replaceAll('\n', '<br>');
    }
}
