export interface SeasonApiI {
    finish_date: Date;
    id: number;
    initial_date: Date;
    is_active: boolean;
    title: string;
    finish_date_to_referees: Date;
}

export class Season {
    public finishDate: Date;
    public startDate: Date;
    public finishRefereeDate: Date;
    constructor(input: SeasonApiI) {
        const { finish_date, initial_date, finish_date_to_referees } = input;
        this.finishDate = new Date(finish_date);
        this.startDate = new Date(initial_date);
        this.finishRefereeDate = new Date(finish_date_to_referees)
    }
}

export interface SeasonDataI {
    finishDate: Date;
    startDate: Date;
    finishRefereeDate: Date;
}
