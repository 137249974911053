import { Component, OnInit, ViewChild } from '@angular/core';
import { Incident } from '@models/incident.model';
import { OptionInteface } from '@models/option.model';
import { PaginatedAPIResponse } from '@models/paginated-api-response.model';
import { IncidentService } from '@services/api/incidents/incidents.service';
import { ReportService } from '@services/api/report/report.service';
import { MORE_FILTERS_NAMES, RepositoryFilters, filterHipoOptions, filterMonthOptions } from './filters.conf';
import { Location } from '@angular/common';
import { MatPaginator } from '@angular/material/paginator';
import { ApiErrorHandlerService } from '@services/api/error/api-error-handler.service';

@Component({
    selector: 'app-repository',
    templateUrl: './repository.component.html',
    styleUrls: ['./repository.component.scss'],
})
export class RepositoryComponent implements OnInit {
    public filterOptions: OptionInteface[];

    public isHipo: boolean = true;
    public filterHipoOptions: OptionInteface[] = filterHipoOptions;
    public riskIncidentsOptions: OptionInteface[];
    public hipoRiskIncidentsOptions: OptionInteface[];
    public filterManagerPerson: OptionInteface[];
    public filterDelegation: OptionInteface[];
    public filterRegionalDirection: OptionInteface[];
    public filterMonthOptions: OptionInteface[] = filterMonthOptions;
    public filterIncidents: OptionInteface[] = [
        { key: '~clear~', value: '-' },
        { key: 'draft', value: 'Borradores' },
        { key: 'in_review', value: 'En revisión' },
        { key: 'finished', value: 'Completos' },
        { key: 'rejected', value: 'Rechazados' },
        { key: 'pending', value: 'Pendientes' },
    ];
    public filterAllIncidents: OptionInteface[] = [
        { key: '~clear~', value: '-' },
        { key: 'in_review', value: 'En revisión' },
        { key: 'finished', value: 'Completos' },
        { key: 'rejected', value: 'Rechazados' },
        { key: 'pending', value: 'Pendientes' },
    ];

    public incidents: Incident[];
    public MAX_INCIDENTS_PER_PAGE: number = 20;
    public TOTAL_INCIDENTS: number = 0;
    public pageSizeOptions: number[] = [10, 20, 50, 100];
    public currentPageNumber: number = 0;

    public nextInboxPage: string;
    public prevInboxPage: string;

    public showMoreFilters: boolean = false;
    public showMyIncidentFilters: boolean = false;
    public showAllIncidentFilters: boolean = true;
    public clearOption: OptionInteface = { key: '~clear~', value: '-' };

    private moreFiltersNames: string[] = MORE_FILTERS_NAMES;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public filters: RepositoryFilters = {
        persons: 'all',
        view: 'repository',
    };

    constructor(
        private reportService: ReportService,
        private indicentService: IncidentService,
        private location: Location,
        private errorService: ApiErrorHandlerService
    ) {}

    get filtersToSend() {
        let result = { ...this.filters };
        if (result.persons === 'me' || 'user' in result)
            delete result['persons'];
        return result;
    }

    ngOnInit(): void {
        this.incidents = [];
        this.filterOptions = [{ key: 'all', value: 'Todos' }];
        sessionStorage.setItem('ferro-page', 'repository');

        this.adjustFilterOptionBasedOnProfile();
        this.handleFilters();
        this.handlePageNavigation();
    }

    handlePageNavigation(): void {
        const pageNumber = sessionStorage.getItem('repository-page-number');
        const pageUrl = sessionStorage.getItem('repository-page-url');

        if (pageNumber && pageUrl && pageNumber != '0' && pageUrl != 'null') {
            this.currentPageNumber = parseInt(pageNumber);
            this.getRepositoryPaged(pageUrl);
        } else {
            this.loadIncidents(this.filters);
        }
    }

    adjustFilterOptionBasedOnProfile(): void {
        const userProfile = localStorage.getItem('user-profile');
        if (userProfile === 'player' && this.filterOptions.length < 2) {
            this.filterOptions.push({ key: 'me', value: 'Míos' });
        } else if (userProfile === 'cheerleader') {
            this.filterAllIncidents.push({ key: 'draft', value: 'Borradores' });
        }
    }

    handleFilters(): void {
        this.loadStoredFilters();
        this.getRisks();
        this.getRegionalDirections();
        if (this.filters && this.moreFiltersNames.some(name => name in this.filters)) {
            //handle the calling of sub-filters that depend on the choice of some of the other sub-filters
            this.handleDependentSubFilters(this.filters);
        } else {
            this.getDelegations();
            this.getUsers();
        }
    }

    loadStoredFilters(): void {
        const storedFilters = localStorage.getItem('repository-filters');
        if (storedFilters) {
            const loadedFilters = JSON.parse(storedFilters);
            this.isHipo = this.filters['hipo'] === 'true' ? true : false;
            this.filters = { ...this.filters, ...loadedFilters };
            const isSelectedMoreFilters = this.moreFiltersNames.some(
                (name) => loadedFilters[name]
            );
            this.showMoreFilters = isSelectedMoreFilters;
        }
    }

    setCurrentPage(pageNumber: number): void {
        if (this.paginator) {
            this.currentPageNumber = pageNumber;
            this.paginator.pageIndex = pageNumber;
            sessionStorage.setItem('repository-page-number', null);
        }
    }

    getRegionalDirections(): void {
        this.indicentService.getRegionalDirection().subscribe(
            (response: any) => {
                this.filterRegionalDirection = response.map((delegation) => {
                    return { key: delegation.id, value: delegation.name };
                });
                this.filterRegionalDirection.unshift(this.clearOption);
            },
            (error) => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );
    }

    getDelegations(filters: any = {}): void {
        this.indicentService.getDelegations(filters).subscribe(
            (response: any) => {
                this.filterDelegation = response.map((delegation) => {
                    return { key: delegation.id, value: delegation.name };
                });
                this.filterDelegation.unshift(this.clearOption);
            },
            (error) => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );
    }

    getUsers(filters: any = {}): void {
        this.indicentService.getRepositoryPlayers(filters).subscribe(
            (response: any) => {
                this.filterManagerPerson = response.map((user) => {
                    const username: string = `${user.first_name} ${user.last_name}`;
                    return { key: user.identifier, value: username };
                });
                this.filterManagerPerson.unshift(this.clearOption);
            },
            (error) => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );
    }

    getRisks(): void {
        this.reportService.getNewReportSelect('riskIncidents', true).subscribe(
            (response: any) => {
                this.hipoRiskIncidentsOptions = response
                    .filter((res) => res.is_hipo === true)
                    .map((option) => {
                        return { key: option.id, value: option.name };
                    });
                this.riskIncidentsOptions = response
                    .filter((res) => res.is_hipo === false)
                    .map((option) => {
                        return { key: option.id, value: option.name };
                    });
                this.hipoRiskIncidentsOptions.unshift(this.clearOption);
                this.riskIncidentsOptions.unshift(this.clearOption);
            },
            (error) => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );
    }

    loadIncidents(filters: RepositoryFilters | null = null): void {
        this.indicentService.getRepository(filters).subscribe(
            (response: PaginatedAPIResponse) => {
                this.loadIncidentsVars(response);
            },
            (error) => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );
    }

    getRepositoryPaged(url: string): void {
        this.indicentService.getIncidentListPage(url).subscribe(
            (response: PaginatedAPIResponse) => {
                this.loadIncidentsVars(response);
            },
            (error) => {
                this.errorService.handleErrorFeedback(error?.error?.key);
            }
        );
    }

    loadIncidentsVars(response: PaginatedAPIResponse): void {
        this.nextInboxPage = response.next;
        this.prevInboxPage = response.previous;
        this.TOTAL_INCIDENTS = response.count;
        this.incidents = <Incident[]>response.results;
        this.incidents.map((incident: Incident) => {
            incident.totalGoals = incident.all_goals_evaluated_questions;
        });
    }

    toggleFilters(): void {
        this.showMoreFilters = !this.showMoreFilters;
    }

    handleDependentSubFilters(filters: RepositoryFilters | null): void {
        let paramsUsers = {};
        let paramsDelegations = {};
        const regionalDirection = filters['regional-direction'];
        const delegation = filters['delegation'];
        if (regionalDirection) {
            paramsDelegations = {
                ...paramsDelegations,
                'regional-direction': regionalDirection
            };
            paramsUsers = {
                ...paramsUsers,
                'regional-direction': regionalDirection,
            };
        }
        if (delegation) {
            paramsUsers = { ...paramsUsers, delegation: delegation };
        }
        this.getDelegations(paramsDelegations);
        this.getUsers(paramsUsers);
    }

    getSubFilter(filter: string, key: any) {
        const params = {};

        switch (filter) {
            case 'regional-direction':
                if (key !== '~clear~') params['regional-direction'] = key;
                this.getDelegations(params);
                this.getUsers(params);
                break;
            case 'delegation':
                if (key !== '~clear~') {
                    params['delegation'] = key;
                } else if (this.filters['regional-direction']) {
                    params['regional-direction'] =
                        this.filters['regional-direction'];
                }
                this.getUsers(params);
                break;
            case 'hipo':
                this.isHipo = key === 'true' ? true : false;
                if (key !== '~clear~') {
                    key = this.isHipo ? 'true' : 'false';
                }
                break;
            case 'persons':
                this.showMyIncidentFilters = key === 'me' ? true : false;
                this.showAllIncidentFilters = key === 'all' ? true : false;
                break;
            case 'filter':
                if (key !== '~clear~') {
                    params['filter'] = key;
                }
                break;
            default:
                break;
        }

        if (key !== '~clear~') {
            this.filters[filter] = key;
        } else {
            if (this.filters[filter] || filter === 'hipo') {
                delete this.filters[filter];
            }
        }
        //reset page number to 0
        this.setCurrentPage(0);

        this.loadIncidents(this.filtersToSend);
        //Se guardan todos los filtros menos...
        // por que se quiere que esté hardcoded siempre, no tiene sentido guardarlo
        if (filter !== 'persons') {
            localStorage.setItem(
                'repository-filters',
                JSON.stringify(this.filters)
            );
        }
    }

    onPageChange(event: any) {
        let pageUrl: string =
            event.previousPageIndex < event.pageIndex
                ? this.nextInboxPage
                : this.prevInboxPage;

        sessionStorage.setItem('repository-page-url', pageUrl);
        sessionStorage.setItem('repository-page-number', event.pageIndex);
        this.getRepositoryPaged(pageUrl);
    }

    goBack(): void {
        this.location.back();
    }
}
