import { ActionsService } from '@services/api/actions/actions.service';
import { Component, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AppUser } from '@models/user.model';
import { LoginService } from '@services/api/login/login.service';
import { ApiErrorHandlerService } from '@services/api/error/api-error-handler.service';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
    public loginForm: UntypedFormGroup;
    public submitted: boolean = false;
    public loginError: boolean = false;
    public userWithoutTeam: boolean = false;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private router: Router,
        private loginService: LoginService,
        private actionsService: ActionsService,
        private errorService: ApiErrorHandlerService
    ) {}

    ngOnInit(): void {
        this.loginForm = this.formBuilder.group({
            email: ['', [Validators.required, Validators.email]],
            password: ['', [Validators.required]]
        });
    }

    get f() {
        return this.loginForm.controls;
    }

    onSubmit() {
        this.loginError = false;
        this.submitted = true;
        if (!this.loginForm.invalid) {
            this.login(this.loginForm.value.email.trim(), this.loginForm.value.password.trim());
        }
    }

    login(email: string, password: string): void {
        this.loginService.login(email, password).subscribe(
            () => {
                this.loginService.getUser().subscribe(
                    (response: AppUser) => {
                        // check if player user has no team
                        if (response.team === null && response.profile === 'player') {
                            this.loginService.logout();
                            this.userWithoutTeam = true;
                        } else {
                            this.actionsService.postAction({ action: 'login' }).subscribe(() => {});
                            this.userRouter(response.profile);
                            localStorage.setItem('user-profile', response.profile);
                        }
                    },
                    error => {
                       this.errorService.handleErrorFeedback(error?.error?.key);
                    }
                );
            },
            error => {
                this.loginError = true;
            }
        );
    }

    /**
     * Depends on userType, user will be redirected to
     * one page or other
     * @param userType
     */
    userRouter(userProfile: string): void {
        if (localStorage.getItem('ferro-onboarding')) {
            if (userProfile === 'trainer') {
                this.router.navigate(['/trainer']);
            } else {
                this.router.navigate(['/team']);
            }
        } else {
            this.router.navigate(['/tutorial']);
        }
    }

    onRecoveryClick(): void {
        this.router.navigate(['/reset-password']);
    }
}
