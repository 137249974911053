import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';

@Injectable({
    providedIn: 'root',
})
export class ApiErrorHandlerService {
    constructor(private _snackBar: MatSnackBar) {}

    private FERROVIAL_ERROR_MESSAGES: { [key: string]: string } = {
        USER_NOT_EXISTS: 'El usuario no existe',
        USER_VALIDATION_ERROR:
            'Error en la validación de la cuenta del usuario',
        NO_ACTIVE_USER_OR_NOT_CHANGEABLE_PASSWORD:
            'No hay ningún usuario activo asociado a esta dirección de correo electrónico o no se puede cambiar la contraseña',
        TOKEN_NOT_VALID:
            'Parece que has accedido con un enlace no válido. Vuelve a solicitar el cambio de contraseña',
        INVALID_MAIL: 'Correo electrónico no válido',
        UPDATE_PROFILE_ERROR: 'Error al actualizar el perfil',
        IMAGE_ERROR: 'La imagen no es válida',
        TEAM_NOT_EXISTS: 'El equipo no existe',
        TEAM_DIVISION_NOT_EXISTS: 'La división de equipo no existe',
        DIVISION_NOT_EXISTS: 'La división no existe',
        CREATE_INCIDENT_ERROR:
            'No se puede crear una incidencia, campos no válidos',
        NOT_DRAFT_INCIDENT:
            'No se puede actualizar el incidente porque no está en estado de borrador',
        MISSING_REQUIRED_FIELD:
            'Falta un campo obligatorio. Por favor, complete todos los campos requeridos.',
        CONTRACT_NOT_EXISTS:
            'El contrato no existe. Por favor, verifique la información ingresada',
        ACTIVITY_NOT_EXISTS:
            'La actividad no existe. Por favor, verifique la información ingresada',
        RISK_INCIDENT_NOT_EXISTS:
            'El incidente de riesgo no existe. Por favor, verifique la información ingresada',
        INCIDENT_NOT_EXISTS:
            'La incidencia no existe. Por favor, verifique la información ingresada',
        NOT_ALL_THE_EVALUATIONS_SECTION:
            'No se han completado todas las evaluaciones de la sección',
        EVALUATION_QUESTION_NOT_EXISTS:
            'La pregunta de evaluación no existe. Por favor, verifique la información ingresada o intente con una pregunta diferente',
        EVALUATION_QUESTION_ALREADY_REPORTED:
            'La pregunta de evaluación ya ha sido reportada',
        EVALUATION_QUESTION_MAXIMUM_SCORE_EXCEEDED:
            'Se ha superado el puntaje máximo de la pregunta de evaluación',
        USER_IS_NOT_REFEREE: 'Solo el equipo de arbitraje pueden evaluar incidentes',
        USER_IS_NOT_PLAYER: 'Solo los/as jugadores/as pueden crear incidentes',
        INCIDENT_USER_CREATED_IS_NOT_USER:
            'El usuario que creó el incidente no es un usuario válido',
        INCIDENT_DATE_CAN_NOT_CHANGE: 'La fecha del incidente no puede cambiar',
        USER_DOES_NOT_HAVE_TEAM: 'El usuario no tiene equipo',
        DELEGATION_NOT_EXISTS: 'La delegación no existe',
        REGIONAL_DIRECTION_NOT_EXISTS: 'La dirección regional no existe',
        SLIDE_NOT_EXISTS: 'La diapositiva no existe',
        STATIC_INFO_NOT_EXISTS: 'La información estática no existe',
        NOTIFICATION_ITEM_NOT_EXISTS: 'El elemento de notificación no existe',
        NOT_ACCEPT_INCIDENTS_BY_DATE: 'No se aceptan incidentes por fecha',
        NOT_ACCEPT_EVALUATION_INCIDENT_BY_DATE:
            'No se aceptan incidentes de evaluación por fecha',
        INMEDIATE_CAUSE_NOT_EXISTS: 'La causa inmediata no existe',
        ROOT_CAUSE_NOT_EXISTS: 'La causa raíz no existe',
        SEASON_DOES_NOT_EXISTS: 'La temporada no existe',
        NOT_ACTIVE_SEASON: 'Temporada no activa',
    };

    getBackendErrorMessage(key: string): string {
        return this.FERROVIAL_ERROR_MESSAGES[key] || key;
    }

    handleErrorFeedback(error: string): void {
        if (error && error !== 'undefined' && error !== 'null') {
            const errorMsg = this.getBackendErrorMessage(error);
            this._snackBar.open(errorMsg, 'X', {
                duration: 3000,
                panelClass: ['error-snackbar'],
            });
        } else {
            console.log(error);
        }
    }
}
