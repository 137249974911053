import {
    ChangeDetectorRef,
    ComponentRef,
    Directive,
    ElementRef,
    HostListener,
    Input,
    Renderer2,
    ViewContainerRef,
} from '@angular/core';
import { TOOLTIP_OFFSET, TooltipI } from '@models/tooltip.model';
import { TooltipComponent } from '@shared/tooltip/tooltip.component';

@Directive({
    selector: '[tooltip]',
})
export class TooltipDirective {
    @Input('tooltip') tooltipData?: TooltipI;
    public tooltipRef: ComponentRef<TooltipComponent> | null = null;

    constructor(
        private vcr: ViewContainerRef,
        private elementRef: ElementRef,
        private renderer: Renderer2,
        private cdr: ChangeDetectorRef
    ) { }

    @HostListener('mouseenter')
    onMouseEnter() {
        if (this.tooltipData?.label) {
            this.tooltipRef = this.vcr.createComponent(TooltipComponent);
            this.tooltipRef.instance.tooltipData = this.tooltipData;
            this.cdr.detectChanges();
            this.setTooltipPosition();
        }
    }

    @HostListener('mouseleave')
    onMouseLeave() {
        this.tooltipRef?.destroy();
        this.tooltipRef = null;
    }

    setTooltipPosition() {
        const $anchorElement = this.elementRef.nativeElement;

        if (this.tooltipRef) {
            const tooltip = this.tooltipRef.instance.tooltipElement.nativeElement;
            const windowWidth = window.innerWidth;
            if ($anchorElement && tooltip) {
                const anchorOffset$ = $anchorElement.getBoundingClientRect();
                const {
                    top: anchorTop,
                    right: anchorRight,
                    left: anchorLeft,
                    bottom: anchorBottom,
                    height: anchorHeight,
                    width: anchorWidth,
                } = anchorOffset$;
                const tooltipOffset$ = tooltip.getBoundingClientRect();
                const { height: tooltipHeight, width: tooltipWidth } = tooltipOffset$;
                const { placement, classes } = this.tooltipData;
                let top: number = 0;
                let left: number = 0;
                let tooltipClasses = classes || [];
                switch (placement) {
                    case 'left':
                        top = anchorTop + (anchorHeight - tooltipHeight) / 2;
                        left = anchorLeft - tooltipWidth - TOOLTIP_OFFSET;
                        tooltipClasses.push('placement-left');
                        break;
                    case 'right':
                        top = anchorTop + (anchorHeight - tooltipHeight) / 2;
                        left = anchorRight + TOOLTIP_OFFSET;
                        tooltipClasses.push('placement-right');
                        break;
                    case 'bottom':
                        top = anchorBottom + TOOLTIP_OFFSET;
                        left = anchorLeft + (anchorWidth - tooltipWidth) / 2;
                        tooltipClasses.push('placement-bottom');
                        break;
                    default:
                        top = anchorTop - (tooltipHeight + TOOLTIP_OFFSET);
                        left = anchorLeft + (anchorWidth - tooltipWidth) / 2;
                        tooltipClasses.push('placement-top');
                        break;
                }

                if (left < 0) {
                    // Window attach if tooltip positioning is outside the beginning of the window
                    left = 0;
                } else if (left + tooltipWidth > windowWidth) {
                    // Window attach if tooltip positioning is outside the end of the window
                    left = windowWidth - tooltipWidth;
                }

                this.renderer.setStyle(tooltip, 'top', `${top}px`);
                this.renderer.setStyle(tooltip, 'left', `${left}px`);

                tooltipClasses.forEach((item) => this.renderer.addClass(tooltip, item));
            }
        }
    }
}
