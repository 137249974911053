import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LoginComponent } from './components/login/login.component';
import { CityComponent } from './components/shared/city/city.component';
import { TopMenuComponent } from './components/shared/top-menu/top-menu.component';
import { ProgressBarComponent } from './components/shared/progress-bar/progress-bar.component';
import { HomeComponent } from './components/pages/home/home.component';
import { UserAvatarComponent } from './components/shared/user-avatar/user-avatar.component';
import { TeamSingleComponent } from './components/pages/team-single/team-single.component';
import { ButtonComponent } from './components/shared/button/button.component';
import { FormErrorsComponent } from './components/shared/form-errors/form-errors.component';
import { NewReportComponent } from './components/pages/new-report/new-report.component';
import { SelectComponent } from './components/shared/select/select.component';
import { RadioButtonGroupComponent } from './components/shared/radio-button-group/radio-button-group.component';

import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatInputModule } from '@angular/material/input';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MAT_DATE_LOCALE } from '@angular/material/core';
import { MenuComponent } from './components/shared/menu/menu.component';
import { OnboardingComponent } from './components/pages/onboarding/onboarding.component';
import { LoginService } from '@services/api/login/login.service';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthGuardService } from '@services/auth/auth-guard.service';
import { ReportService } from '@services/api/report/report.service';
import { TeamService } from '@services/api/team/team.service';
import { DatePipe } from '@angular/common';
import { InboxComponent } from './components/pages/inbox/inbox.component';
import { RankingMenuComponent } from './components/pages/ranking-menu/ranking-menu.component';
import { ReportComponent } from './components/pages/report/report.component';
import { RankingIndividualComponent } from './components/pages/ranking-individual/ranking-individual.component';
import { FaqComponent } from './components/pages/faq/faq.component';

import { AppHttpInterceptor } from '@services/http.interceptor';
import { IncidentService } from '@services/api/incidents/incidents.service';
import { EvaluateComponent } from './components/pages/evaluate/evaluate.component';
import { EvaluationService } from '@services/api/evaluation/evaluation.service';
import { SportsMinuteComponent } from './components/pages/sports-minute/sports-minute.component';
import { TrainerComponent } from './components/pages/trainer/trainer.component';
import { TeamMembersComponent } from './components/pages/team-members/team-members.component';
import { RepositoryComponent } from './components/pages/repository/repository.component';
import { SportMinuteDetailsComponent } from './components/pages/sport-minute-details/sport-minute-details.component';
import { NotificationsComponent } from './components/shared/notifications/notifications.component';
import { CustomDateAdapter } from './providers/helpers/custom-date-adapter';
import { PasswordRecoveryComponent } from './components/pages/password-recovery/password-recovery.component';
import { NotificationCardComponent } from './components/shared/notifications/notification-card/notification-card.component';
import { MedalsComponent } from './components/pages/medals/medals.component';
import { TooltipDirective } from './directives/tooltip/tooltip.directive';
import { TooltipComponent } from '@components/shared/tooltip/tooltip.component';

@NgModule({
    declarations: [
        AppComponent,
        LoginComponent,
        CityComponent,
        TopMenuComponent,
        HomeComponent,
        UserAvatarComponent,
        TeamSingleComponent,
        ButtonComponent,
        FormErrorsComponent,
        NewReportComponent,
        SelectComponent,
        RadioButtonGroupComponent,
        ProgressBarComponent,
        MenuComponent,
        OnboardingComponent,
        InboxComponent,
        RankingMenuComponent,
        ReportComponent,
        RankingIndividualComponent,
        FaqComponent,
        EvaluateComponent,
        SportsMinuteComponent,
        TrainerComponent,
        TeamMembersComponent,
        RepositoryComponent,
        SportMinuteDetailsComponent,
        NotificationsComponent,
        PasswordRecoveryComponent,
        NotificationCardComponent,
        MedalsComponent,
        TooltipComponent,
        TooltipDirective
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        NgxMaterialTimepickerModule,
        BrowserAnimationsModule,
        MatDatepickerModule,
        MatInputModule,
        MatNativeDateModule,
        HttpClientModule,
        MatSnackBarModule,
        MatPaginatorModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            useClass: AppHttpInterceptor,
            multi: true
        },
        { provide: MAT_DATE_LOCALE, useValue: 'es-ES' },
        { provide: DateAdapter, useClass: CustomDateAdapter },
        LoginService,
        AuthGuardService,
        ReportService,
        TeamService,
        DatePipe,
        IncidentService,
        EvaluationService
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
