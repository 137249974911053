import { APIMedalUserI } from "@models/medals.model";

/** This are the production ID roles that can change valid status of
 * the incident: "Responsable Zona SSL" and "Técnico SSL"
 */
export const USER_ALLOWED_ROLES_CHANGE_INCIDENT_STATUS: number[] = [5, 11];

export interface ApiUserInterface {
    access_token: string;
    expires_in: number;
    token_type: string;
    scope: string;
    refresh_token: string;
}

export interface APIUserMiniI {
    avatar: string | null;
    email: string;
    first_name: string;
    identifier: string;
    last_name: string;
    n_exp: string;
    profile: string;
    total_goals: number;
    username: string;
    medals: APIMedalUserI[];
}

export interface AppUser extends APIUserMiniI {
    all_points_evaluated_questions: any[];
    busines_unit: any;
    delegation: any;
    last_id: number;
    manager: any;
    notify_user: boolean;
    regional_directions: RegionalDirection[];
    role: Role;
    team?: string | null;
    rd_business?: any;
    zone?: any;
}
interface RegionalDirection {
    id: number;
    name: string;
}

interface Role {
    id: number;
    name: string;
}

export interface SimpleUser {
    identifier: string;
    username: string;
    avatar: string;
}
