import { environment } from './../../../environments/environment';

// NOTE: Dictionay of All Calls
export const calls = {
    login: 'auth/token',
    user: 'user',
    riskIncidents: 'risk-incidents',
    contract: 'contract',
    activityList: 'activity',
    workDayHour: 'work-day-hour',
    team: 'team',
    incident: 'incident',
    evaluationQuestions: 'evaluation-questions',
    evaluationIncident: 'evaluation-incident',
    rankTeams: 'ranking-teams',
    rankUsers: 'ranking-users',
    news: 'static-info',
    player: 'player',
    delegation: 'delegation',
    staticInfo: 'static-info',
    notification: 'notification',
    division: 'division',
    regionalDirection: 'regional-direction',
    rootCauses: 'root-causes',
    inmediateCauses: 'inmediate-causes',
    passRecovery: 'password-reset',
    passRecoveryConfirm: 'password-reset-confirm',
    actions: 'actions',
    seasons: 'seasons',
    currentSeason: 'seasons/get-current-season',
    teams: 'team/list-active-teams',
    medals: 'medals',
    appSettings: 'appsettings'
};

export function getCall(call, id = null, params = {}) {
    let url = environment.urlBack + calls[call] + '/';
    if (id != '' && id != null && id != undefined) {
        url += id + '/';
    }
    if (params) {
        url += '?';
        for (const key in params) {
            url += key + '=' + params[key] + '&';
        }
        url = url.slice(0, -1);
    }
    return url;
}
