import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuardService } from './services/auth/auth-guard.service';
import { LoginComponent } from './components/login/login.component';
import { HomeComponent } from './components/pages/home/home.component';
import { NewReportComponent } from './components/pages/new-report/new-report.component';
import { OnboardingComponent } from './components/pages/onboarding/onboarding.component';
import { TeamSingleComponent } from './components/pages/team-single/team-single.component';
import { InboxComponent } from './components/pages/inbox/inbox.component';
import { RankingMenuComponent } from './components/pages/ranking-menu/ranking-menu.component';
import { ReportComponent } from './components/pages/report/report.component';
import { RankingIndividualComponent } from './components/pages/ranking-individual/ranking-individual.component';
import { FaqComponent } from './components/pages/faq/faq.component';
import { EvaluateComponent } from './components/pages/evaluate/evaluate.component';
import { SportsMinuteComponent } from './components/pages/sports-minute/sports-minute.component';
import { SportMinuteDetailsComponent } from './components/pages/sport-minute-details/sport-minute-details.component';
import { TrainerComponent } from './components/pages/trainer/trainer.component';
import { TeamMembersComponent } from './components/pages/team-members/team-members.component';
import { RepositoryComponent } from './components/pages/repository/repository.component';
import { PasswordRecoveryComponent } from './components/pages/password-recovery/password-recovery.component';
import { MedalsComponent } from '@components/pages/medals/medals.component';

const routes: Routes = [
    { path: '', redirectTo: '/team', pathMatch: 'full' },
    { path: 'login', component: LoginComponent },
    { path: 'reset-password', component: PasswordRecoveryComponent },
    { path: 'reset-password-confirm/:token', component: PasswordRecoveryComponent },
    {
        path: '',
        component: HomeComponent,
        canActivate: [AuthGuardService],
        children: [
            {
                path: 'team',
                component: TeamSingleComponent,
                canActivate: [AuthGuardService],
                data: {
                    profiles: ['player', 'trainer', 'referee', 'cheerleader'],
                },
            },
            {
                path: 'team/:team_id',
                component: TeamSingleComponent,
                canActivate: [AuthGuardService],
                data: {
                    profiles: ['player', 'trainer', 'referee', 'cheerleader'],
                },
            },
            {
                path: 'new-report',
                component: NewReportComponent,
                canActivate: [AuthGuardService],
                data: {
                    profiles: ['player'],
                },
            },
            {
                path: 'new-report/:report_id',
                component: NewReportComponent,
                canActivate: [AuthGuardService],
                data: {
                    profiles: ['player'],
                },
            },
            {
                path: 'inbox',
                component: InboxComponent,
                canActivate: [AuthGuardService],
                data: {
                    profiles: ['referee'],
                },
            },
            {
                path: 'rankings',
                component: RankingMenuComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'rankings/:type',
                component: RankingIndividualComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'sports-minute',
                component: SportsMinuteComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'sports-details',
                component: SportMinuteDetailsComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'report/:report_id',
                component: ReportComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'evaluate/:report_id',
                component: EvaluateComponent,
                canActivate: [AuthGuardService],
                data: {
                    profiles: ['referee'],
                },
            },
            {
                path: 'faq',
                component: FaqComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'trainer',
                component: TrainerComponent,
                canActivate: [AuthGuardService],
                data: {
                    profiles: ['trainer'],
                },
            },
            {
                path: 'team-members/:team_id',
                component: TeamMembersComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'repository',
                component: RepositoryComponent,
                canActivate: [AuthGuardService],
            },
            {
                path: 'medals',
                component: MedalsComponent,
                canActivate: [AuthGuardService],
            }
        ],
    },
    {
        path: 'tutorial',
        component: OnboardingComponent,
        canActivate: [AuthGuardService],
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, {})],
    exports: [RouterModule],
})
export class AppRoutingModule {}
