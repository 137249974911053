import { Component, OnInit } from '@angular/core';
import { APIMedalI, Medal, MEDALS_DEFAULT_ASSET } from '@models/medals.model';
import { ApiErrorHandlerService } from '@services/api/error/api-error-handler.service';
import { MedalsService } from '@services/api/medals/medals.service';

@Component({
    selector: 'app-medals',
    templateUrl: './medals.component.html',
    styleUrls: ['./medals.component.scss']
})
export class MedalsComponent implements OnInit {
    public medals: Medal[] = [];
    public loading: boolean = false;

    public defaultImage = MEDALS_DEFAULT_ASSET;

    constructor(
        private medalsService: MedalsService,
        private errorService: ApiErrorHandlerService
    ) {}

    ngOnInit(): void {
        this.loadMedals();
    }

    loadMedals(): void {
        this.loading = true;
        this.medalsService.getMedals().subscribe(
            (response: APIMedalI[]) => {
                this.setMedals(response);
                this.loading = false;
            }, error => {
                this.errorService.handleErrorFeedback(error?.error?.key);
                this.loading = false;
            }
        );
    }

    setMedals(response: APIMedalI[]): void {
        this.medals = response.map(medal => new Medal(medal));
    }
}
